export const markerStyles = [
  {
    'id': 'highlight-active-points',
    'type': 'circle',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['==', 'active', 'true']],
    'paint': {
      'circle-radius': 7,
      'circle-color': '#000000'
    }
  },
  {
    'id': 'points-are-blue',
    'type': 'circle',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['==', 'active', 'false']],
    'paint': {
      'circle-radius': 5,
      'circle-color': '#000088'
    }
  }
]

let old =  [
  {
    'id': 'devices-unactive',
    'type': 'symbol',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['==', 'active', 'false']],
    'layout': {
      'icon-image': ['get', 'user_cat_icon'],
      'icon-size': 1.6,
      'icon-text-fit': 'both',
      'icon-ignore-placement': true,
      'icon-text-fit-padding': [2, 5, 2, 5],
      'icon-allow-overlap': true,
      'text-field': ['get', 'user_cat_abbr'],
      'text-anchor': 'center',
      'text-size': 13
    },
    'paint': {
      'text-color': '#ffffff'
    }
  },
  {
    'id': 'devices-active',
    'type': 'symbol',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['==', 'active', 'true']],
    'layout': {
      'icon-image': ['get', 'user_cat_icon'],
      'icon-size': 1.6,
      'icon-text-fit': 'both',
      'icon-text-fit-padding': [2, 5, 2, 5],
      'icon-allow-overlap': true,
      'text-field': ['get', 'user_cat_abbr'],
      'text-anchor': 'center',
      'text-size': 13
    },
    'paint': {
      'text-color': '#ffffff',
      'icon-halo-width': 10,
      'icon-halo-blur': 5,
      'icon-halo-color': '#ffffff'
    }
  },
]
