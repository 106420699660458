import actions from './deplo.actions';
import getters from './deplo.getters';
import mutations from './deplo.mutations';

/* deplo.store.js */
// State object
const state = {
    // state for MapItems
    mapitems: [],
    

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
