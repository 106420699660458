<template>
    <card-component title="Last Snapshots" icon="cctv" class="has-table no-outside" style="overflow: hidden">
            <b-carousel style="margin: -3px"  :indicator-inside="true" :indicator="false" :pause-hover="false">
                <b-carousel-item v-for="(camera, i) in cameras" :key="i">
                    <span class="images">
                      <camera-snapshot-component :camera_id="camera.id" :name="camera.name" :project_id="this.project_id" :thumb="false" >

                      </camera-snapshot-component>
                    </span>
                </b-carousel-item>


            </b-carousel>



    </card-component>
</template>

<script>

    import CameraSnapshotComponent from "../devices/camera/CameraSnapshotComponent";
    import CardComponent from "../CardComponent";
    export default {
        name: "CameraCarouselComponent",
        components: {CardComponent, CameraSnapshotComponent},
        props: ['cameras', 'project_id'],
        data() {
            return {
                gallery: false
            }
        },
        methods: {

        }


    }
</script>

<style lang="scss" scoped>


</style>