export default {
    fetchAllMapItems({ commit }) {
        this.$axios.get('/api/mapitems').then(response => {
            commit('setMapItems', response.data);
        });
    },

    createMapItem({ commit, resolve }, data) {
        return new Promise((resolve, reject) => {
            this.$axios.post('/api/mapitems/', data).then(response => {
                commit('addMapItem', response.data.data);
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    }


}