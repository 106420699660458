/* mapbox.store.js */

// State object
import bbox from '@turf/bbox';
import Vue from 'vue';
import EyeIcon from "../../components/EyeIcon";
import CameraCarouselComponent from "../../components/package/CameraCarouselComponent"

const state = {
    mapbox: { },
    mymap: { },
    currentprojects: {},
    currentpackages: {},
    expanded: true,
    header_status: null,
    header_stack: null,

    loaded: false,
}

// Getter functions
const getters = {

}

// Actions
const actions = {

    initMap({commit}, map) {
        commit('loadMap', map)

        // Add controls to the map
        // Add event functions
    },
    initMapbox({commit}, mapbox) {
        commit('loadMapbox', mapbox)

    },

    drawProjects({commit}, params) {
        commit("deleteMarkers", true)
        commit("drawProjects", params)
        commit("snapToBox", params.geo)

    },
    drawPackages({commit}, params) {
        commit("deleteMarkers", false)
        commit("drawPackages", params)
        if(params.snap === true) {
            commit("snapToBox", params.geo)
        }
    },
    drawPackagesWithoutSnap({commit}, params) {
        commit("deleteMarkers", false)
        commit("drawPackages", params)
    },
    focusOnPackage({commit}, params) {
        if(!state.currentpackages[params.package.id]) {
            commit("drawSinglePackage", params)

        }
        //commit("flyToPackage", params.package)
        commit("flyToPoint", [params.package.long, params.package.lat])
    },
    flyToPoint({commit}, params) {
        commit("flyToPoint", params)
    },

    setStack({commit},list){
        commit('setStack', list)
    },
    setHeader({commit}, payload){
        commit('setHeader', payload)
    },
    resize({commit}){
        commit('resize')
    },


}

// Mutations
const mutations = {
    loadMapbox (state, mapbox) {
        state.mapbox = mapbox
    },
    loadMap (state, map) {
        state.mymap = map
    },


    deleteMarkers(state, expanded) {
        state.expanded = expanded

        for(let key in state.currentprojects) {
            state.currentprojects[key].remove();
        }
        for(let key in state.currentpackages) {
            state.currentpackages[key].remove();
        }
    },

    drawProjects(state, params) {
        for(let i = 0; i < params.projects.length; i++){
            let marker = params.ctx.$makeMarker({
                lnglat: [params.projects[i].long, params.projects[i].lat],
                anchor: "center",
                props: {
                    status: params.projects[i].status,
                    type: "projects",
                    id: params.projects[i].id,
                    hasText: false,
                },
                component: EyeIcon,
                zIndex: 0
            })
            marker.addTo(state.mymap)
            state.currentprojects[params.projects[i].id] = marker;
        }
        //let box = bbox(params.geo);

    },
    drawPackages(state, params) {
        for(let i = 0; i < params.packages.length; i++){
            let marker = params.ctx.$makeMarker({
                lnglat: [params.packages[i].long, params.packages[i].lat],
                anchor: "center",
                props: {
                    status: params.packages[i].status,
                    type: "package",
                    id: params.packages[i].id,
                    hasText: true,
                },
                component: EyeIcon,
                zIndex: 0

            })

            marker.addTo(state.mymap)
            state.currentpackages[params.packages[i].id] = marker;
        }

    },
    drawSinglePackage(state, params) {
        state.expanded = false
        let marker = params.ctx.$makeMarker({
            lnglat: [params.package.long, params.package.lat],
            anchor: "center",
            props: {
                status: params.package.status,
                type: "package",
                id: params.package.id
            },
            component: EyeIcon,
            zIndex: 0
        })
        marker.addTo(state.mymap)
        state.currentpackages[params.package.id] = marker;

    },

    flyToPoint(state, coordinates) {
        state.mymap.flyTo({center: coordinates, zoom: 17.4});
    },
    flyToPackage(state, the_package) {
        state.mymap.flyTo({center: [the_package.long, the_package.lat], zoom: 17.4});

        const CarouselPopup = Vue.extend(CameraCarouselComponent)

        const popup = new mapboxgl.Popup()
            .setLngLat({ lng: the_package.long, lat: the_package.lat })
            .setHTML('<div id="vue-popup-content"></div>')
            .addTo(state.mymap)

        new CarouselPopup({
            propsData: { cameras: the_package.cameras, package_id: the_package.id },
        }).$mount('#vue-popup-content')

        popup._update()


    },
    snapToBox(state, geo) {
        let box = bbox(geo);
        state.mymap.fitBounds(box, { padding: 100, maxZoom: 16.8, linear: true, duration: 0 });
    },

    setStack(state, list){
        Vue.set(state, 'titlestack', list);
    },

    setHeader(state, payload){
        if(payload.stack) {
            Vue.set(state, 'header_stack', payload.stack);
        } else { Vue.set(state, 'header_stack', null) }

        if(payload.status) {
            Vue.set(state, 'header_status', payload.status);
        } else { Vue.set(state, 'header_status', null);}

    },

    SET_PROJECTS(state, projects) {
        state.all_projects = projects
    },

    resize(state) {
        state.mymap.resize()
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
