<template>
  <div id="wrapper">
    <div id="over_map">
      <card-component class="has-table">
        <transition name="fade" :duration="200" mode="out-in">
          <router-view name="side_view"/>
        </transition>
      </card-component>
    </div>
    <section class="section">
      <mapbox ref="themap" :access-token="accessToken" :map-options="mapStyle"
              :geolocate-control="{
                      show: true,
                      position: 'top-right',
                    }"
              @map-init="mapInitialized"
              @map-load="fetchDevices">
      </mapbox>
    </section>

  </div>

</template>

<script>
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import Mapbox from 'mapbox-gl-vue'
import axios from 'axios'
import * as markers from '../markerStyles.js'
import settings from "@/store/modules/settings.store";
import mapbox from "@/store/modules/mapbox.store";
import deplo from "../store/deplo.store";
import CardComponent from '@/components/CardComponent'
import {mapActions} from "vuex";


export default {
  name: "DeploHome",
  components: {
    Mapbox,
    CardComponent
  },
  data () {
    return {
      accessToken: 'pk.eyJ1IjoibHVja2FzZGMiLCJhIjoiY2sxdWR6d3B5MDkwOTNpcGFuNXh3dGhzNSJ9.nBYgv6LPBGFGDx56p8mRQQ',
      mapStyle: {
        style: 'mapbox://styles/luckasdc/ck7lt4eao0up71in4wu61e03u'
      },
      publicPath: process.env.BASE_URL,
      devices: {
      },
      draw: new MapboxDraw({
        userProperties: true,
        displayControlsDefault: false,
        controls: {
          point: true,
          line_string: true,
          trash: true
        },
        styles: markers.markerStyles
      }),
      themap: {},
      selected: null,
      isCreating: false
    }
  },

  ready () {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox
  },

  created() {
    if(!settings.isRegistered) {
      this.$store.registerModule('settings', settings)
      settings.isRegistered = true
    }
    if(!mapbox.isRegistered) {
      this.$store.registerModule('mapbox', mapbox)
      mapbox.isRegistered = true
    }
    if(!deplo.isRegistered) {
      this.$store.registerModule('deplo', deplo)
      deplo.isRegistered = true
    }

    // this.$store.commit('settings/asideRightToggle', false)
    // this.$store.commit('settings/asideMobileStateToggle', false)
    //this.$store.commit('settings/fullPage', true)
    this.$store.commit('settings/asideRightToggle', false)
    this.$store.commit('settings/navBarOnly', true)


  },

  methods: {

    mapInitialized: function (map) {
      this.themap = map

      // Add controls to the map
      this.themap.addControl(this.draw, 'top-right')
      // Add event functions
      this.themap.on('draw.create', (e) => {
        console.log('creating device...')
        this.createDevice(e)
      })
      this.themap.on('draw.update', (e) => {
        console.log('moving devices...')
        this.moveDevices(e)
      })
      this.themap.on('draw.selectionchange', (e) => {
        if (!this.isCreating) {
          console.log('selecting devices...')
          this.selectDevices(e)
        }
      })
    },
    async fetchDevices () {
      console.log('hey')
      this.isCreating = false
      let thisIns = this
      await axios.get('http://localhost:8000/devices.geojson')
          .then(function (response) {
            thisIns.devices = response.data
            thisIns.draw.set({
              'type': response.data.type,
              'features': response.data.features
            })
          })
          .catch((error) => {
            this.$buefy.toast.open({
              message: `Error: ${error.message}`,
              type: 'is-danger',
              position: 'is-bottom',
              queue: false
            })
          })
    },

    async moveDevices (e) {
      console.log(e)
      for (let i = 0; i < e.features.length; i++) {
        await axios.patch('http://localhost:8000/devices/' + e.features[i].id + '/', {
          geom: JSON.stringify(e.features[i].geometry)
        }).catch(function (error) {
          this.$buefy.toast.open({
            message: `Error: ${error.message}`,
            type: 'is-danger',
            position: 'is-bottom',
            queue: false
          })
        })
      }
      this.$buefy.snackbar.open({
        message: 'Devices Moved',
        queue: false
      })
    },
    selectDevices (e) {
      this.isCreating = false
      this.selected = e
      if(e.features[0]) {
        this.themap.easeTo({center: e.features[0].geometry.coordinates, zoom: 18.5})
        if(e.features[0].properties.mapitem_id) {
          this.$router.push({ name: 'mapitems.detail', params: {id: e.features[0].properties.mapitem_id}, replace: true })
        }
      }
      else {
        this.$router.push({ name: 'mapitems.overview', replace: true })

      }

    },
    async createDevice (e) {

      this.$store.dispatch('deplo/createMapItem',
          {
            geojson: JSON.stringify(e.features[0].geometry),
            area_id: 1, // TODO
            project_id: 2, // TODO
            category_id: 1, // TODO
            status: "DRAFT"
          }
      ).then(resp => {
        this.draw.setFeatureProperty(e.features[0].id, 'mapitem_id', resp.data.data.id)
        this.$router.push({ name: 'mapitems.detail', params: {id: resp.data.data.id}, replace: true })
      })

    }
  }
}
</script>

<style scoped>

#map {
  width: 100%;
  height: 100%;
}

.section{
  height: 100vh;
  padding: 0;
  padding-top: 0;
}
#wrapper {
  position: relative;
}
#over_map {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 30%;
  z-index: 3; }
</style>
